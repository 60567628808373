<template>
  <v-container class="content" v-if="user.id">
    <v-row no-gutters>
      <v-col align="center">
        <h1 class="mb-8">{{ user.firstName + ' ' + user.lastName + ' - ' + user.email }}</h1>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-btn color="red" @click="$router.push({name: 'adminUsersList'})" dark>
          Cancel
        </v-btn>
      </v-col>
      <v-col align="right" v-if="user.type !== 'ADMIN' || user.id === me.id">
        <v-btn color="light-green darken-2" @click="save" :loading="loading" dark>
          Save
          <v-icon right>mdi-content-save-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="3">
          <v-card-title>User Info</v-card-title>
          <v-card-text>
            <v-row no-gutters>
              <v-col cols="6">
                Id: {{ user.id }}
              </v-col>
              <v-col cols="6">
                Created: {{ new Date(user.createdDate).toLocaleString() }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6">
                Account Type: {{ user.type }}
                <user-icon :type="user.type"></user-icon>
              </v-col>
              <v-col cols="6">
                Status: {{ user.status }}

                <v-btn
                    v-if="user.status === 'ENABLED'"
                    color="amber darken-2"
                    @click="changeStatus('DISABLED')"
                >
                  Disable
                </v-btn>
                <v-btn
                    v-if="user.status === 'DISABLED' || user.status === 'CREATED'"
                    color="light-green darken-2"
                    dark
                    @click="changeStatus('ENABLED')"
                >
                  Enable
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field outlined v-model="user.firstName" label="First Name"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field outlined v-model="user.lastName" label="Last Name"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field outlined v-model="user.email" label="Email"></v-text-field>
              </v-col>
              <v-col cols="6" v-if="user.id === me.id">
                <v-btn v-if="!changePassword" @click="changePassword = true" color="primary">Change My Password</v-btn>
                <v-text-field v-else outlined v-model="user.password" label="Password"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="user.company">
      <v-col>
        <v-card elevation="3">
          <v-card-title>Company</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                Id: {{ user.company.id }}
              </v-col>
              <v-col cols="4">
                Created: {{ new Date(user.company.createdDate).toLocaleString() }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field outlined v-model="user.company.name" label="Name"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field outlined v-model="user.company.license" label="ABC License #"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field outlined v-model="user.company.address" label="Address"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import axios from "axios";
import UserIcon from "@/components/general/UserIcon.vue";
import {mapState} from "vuex";

export default {
  components: {UserIcon},
  name: "AdminUserView",
  metaInfo: {
    title: "User View",
  },
  data() {
    return {
      user: {
        company: {}
      },
      id: null,
      loading: false,
      changePassword: false,
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get("/api/admin/user/" + this.id).then((response) => {
        this.user = response.data;
      });
    },
    save() {
      this.loading = true;

      axios
          .put("/api/admin/user/" + this.user.id, this.user)
          .then(() => {
            this.$store.dispatch('banner/open', {text: 'User ' + this.user.email + ' Updated!'})
            this.$router.push({name: 'adminUsersList'})
          })
          .finally(() => {
            this.loading = false;
          });
    },
    changeStatus(status) {
      this.user.status = status

      axios
          .put("/api/admin/user/" + this.user.id, this.user)
          .then(() => {
            this.$store.dispatch('banner/open', {text: 'User ' + this.user.email + ' Updated!'})
          })
          .finally(() => {
            this.fetchData()
          });
    },
  },
  computed: {
    ...mapState({
      me: (state) => state.admin.user,
    }),
  }
};
</script>

<style scoped>
</style>
